import React from "react";
import { Loader } from "@ifgengineering/component-library";
import IFGLoader from "@assets/images/ifg-loader.gif";
import styled from "styled-components";

const LoaderFilter = styled.div`
  filter: brightness(0) saturate(100%) invert(33%) sepia(93%) saturate(3101%)
    hue-rotate(220deg) brightness(101%) contrast(107%);
`;

const Loading: React.FC = () => (
  <Loader>
    <LoaderFilter>
      <img width="90" src={IFGLoader} />
    </LoaderFilter>
  </Loader>
);

export default Loading;
