import React, { FC, useLayoutEffect } from "react";
import { SEO } from "@wealthComponents/seo";
import { theme } from "@ifgengineering/component-library";
import { useDispatch, useSelector } from "react-redux";
import { getCharity } from "@components/Charity/selectors";
import Loading from "@components/Loading";
import store from "@Wills/store";
import { fetchCharityByKeyAPI } from "@components/Charity/actions";
import {
  AuthPage,
  PromoTwo,
  PromoTwoBody,
  CharityLandingPage,
  AuthComponentsProvider,
} from "@ifgengineering/client-auth-components";
import { RECAPTCHA_CLIENT_KEY } from "gatsby-env-variables";
import authSdk from "@components/Login/authSDK";
import {
  isCur8InWealthEnabled,
  isLayoutBEnabled,
  isMagicCodeEnabled,
} from "@helpers/useFeatureFlag";
import { logException } from "@helpers/logException";
import useAnalytics from "@helpers/useAnalytics";
import {
  loginAction,
  resetPassword,
  sendResetPasswordLink,
  signupAction,
  verifyEmail,
} from "@components/Login/actions";

// name to be changed to Auth once we remove all legacy auth
const NewAuth: FC = () => {
  const host = location.host;
  const [charityKey] = host.split("."); // comment this line to test easily
  // const charityKey = "muslimcharity"; // uncomment this line to test easily
  const charity = useSelector(getCharity);
  const dispatch = useDispatch<typeof store.dispatch>();
  const getBackgroundColor = () => {
    switch (true) {
      case charity.loading:
        return theme.colors.WHITE;
      default:
        return "linear-gradient(180deg, #192a36, #000000)";
    }
  };

  const { passwordlessVerify, passwordlessStart } = authSdk;

  useLayoutEffect(() => {
    const charity = async () => {
      dispatch(fetchCharityByKeyAPI(charityKey)).unwrap();
    };

    if (charityKey) {
      charity();
    }
  }, [charityKey]);

  const PromoPage = charity.loading ? (
    <Loading />
  ) : charity?.id ? (
    <CharityLandingPage charity={charity} />
  ) : (
    <PromoTwo />
  );

  const MobileFooter = !charity?.id ? () => <PromoTwoBody /> : undefined;

  return (
    <AuthComponentsProvider
      isCur8InWealthEnabled={isCur8InWealthEnabled}
      isLayoutBEnabled={isLayoutBEnabled}
      isMagicCodeEnabled={isMagicCodeEnabled}
      logException={logException}
      recaptchaClientKey={RECAPTCHA_CLIENT_KEY}
      useAnalytics={useAnalytics}
      verifyEmail={verifyEmail}
      loginAction={loginAction}
      signupAction={signupAction}
      sendResetPasswordLink={sendResetPasswordLink}
      resetPassword={resetPassword}
      passwordlessVerify={passwordlessVerify}
      passwordlessStart={passwordlessStart}
      charity={charity}
    >
      <SEO title="Islamic Finance Guru" />
      <AuthPage
        Promo={() => PromoPage}
        MobileFooter={MobileFooter}
        showHeader={true}
        showTrustBox={true}
        isCharity={!!charity?.id}
        authRedirect={"/app/wills"}
        backgroundColor={getBackgroundColor()}
      />
    </AuthComponentsProvider>
  );
};

export default NewAuth;
